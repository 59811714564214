.photos {
  text-align: center;
  padding: 1rem 2rem 80px;
  line-height: 0;
}
.photos .photo {
  display: inline-block;
  width: 200px;
  height: 200px;
  overflow: hidden;
  text-align: center;
  position: relative;
  cursor: pointer;
  background: #dcdcdc;
  cursor: pointer;
  margin: 2px;
}
.photos .photo:hover {
  /* outline: 5px solid #85b7d9; */
  /* overflow: initial !important; */
/*box-shadow: 0 0 2px 2px grey;*/
}
.photos .photo .image {
  position: relative;
  position: static;
}
/* .photos .photo .image :hover {
  z-index: 1000;
  outline: 4px solid white;
} */
.photos .photo .image img {
  position: absolute;
  margin: auto;
  left: -300px;
  right: -300px;
}