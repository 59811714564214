.photo-viewer {
    /* display: block; */
    text-align: center;
}

/* .tag-tools{
    position: absolute;
    flo
    margin-top: 10px;
} */

.tag-tools{
    width: 150px;
}

.tag-description{
    /* position: absolute; */
    position: relative;
    margin: 0 auto;
    text-align: center;
    /* right: 0px;
    top: 0px; */
    background-color: #fff;
    /* box-shadow: 0 1px 2px 0 rgba(34,36,38,.15); */
    /* margin: 1rem 0; */
    padding: 1em;
    /* border-radius: .28571429rem; */
    /* border: 1px solid rgba(34,36,38,.15); */
    width: 500px
}

.tag-name {
    display: inline-block;
    white-space: nowrap;
    word-wrap: break-word;
    line-height: 1;
    vertical-align: baseline;
    margin: 0 .14285714em;
    background-color: #e8e8e8;
    border-color: #e8e8e8;
    background-image: none;
    padding: .5833em .833em;
    color: rgba(0,0,0,.6);
    text-transform: none;
    font-weight: 700;
    border-radius: .28571429rem;
    -webkit-transition: background .1s ease;
    transition: background .1s ease;
}

.tag-description.tag-name{
    float: right;
    clear: both;
    margin: .25rem 0;
}

.menu.toolbar {
    position: fixed;
    right: 1rem;
    z-index: 1;
}

.menu.toolbar .item>.ui.label {
    margin: 0;
    float: none;
}

.ui.segment.tags {
    position: fixed;
    text-align: right;
    right: 1rem;
    top: 300px
}

.ui.segment.tags .ui.label {
    float: right;
    clear: both;
    margin: .25rem 0;
}

.photo-edit {
    text-align: center;
    padding-top: 5em;
}

#canvas {
    /*width:2000px;*/
    /*height:2000px;*/
    position: relative;
    outline: 1px solid gainsboro;
    /*border: 10px solid transparent;*/
    cursor: default;
    display: inline-block;
}

#canvas img {
    min-width: 400px;
    min-height: 400px;
    max-width: 800px;
    max-height: 800px;
}

#canvas.drawing {
    cursor: crosshair;
}

.rectangle {
    display: block;
    border: 1px solid white;
    box-shadow: 0 0 2px 2px gray;
    position: absolute;
    pointer-events: auto;
}

.rectangle.idle {
    display: none;
}

.rectangle.drawing {
    pointer-events: none;
}

.rectangle:hover {
    box-shadow: 0 0 2px 2px gainsboro;
}

.rectangle[data-tag]:hover::before {
    content: attr(data-tag);
    top: 5px;
    left: 5px;
    position: absolute;
    display: inline-block;
    line-height: 1;
    vertical-align: baseline;
    margin: 0 .14285714em;
    background-color: #E8E8E8;
    background-image: none;
    padding: .5833em .833em;
    color: rgba(0, 0, 0, .6);
    text-transform: none;
    font-weight: 700;
    border: 0 solid transparent;
    border-radius: .28571429rem;
    -webkit-transition: background .1s ease;
    transition: background .1s ease;
    font-size: 12px;
}

.boundary {
    overflow: hidden;
}

.boundary img {}

#tagger {
    display: none;
    position: absolute;
    width: 200px;
    text-align: center;
    z-index: 1;
}

#tagger.tagging {
    display: block;
}

.ui.save.button:not(.saved) .icon {
    display: none;
}

.message {
    text-align: center;
}
.rectangle {
	display: block;
	border: 1px solid white;
	box-shadow: 0 0 2px 2px gray;
	position: absolute;
	pointer-events: auto;
}
.rectangle.idle{
	display: none;
}
.rectangle.drawing {
	pointer-events: none;
}
.rectangle:hover {
	box-shadow: 0 0 2px 2px gainsboro;
}

.rectangle[data-tag]:hover::before{
	content: attr(data-tag);
	top: 5px;
	left: 5px;
	position: absolute;

	display: inline-block;
	line-height: 1;
	vertical-align: baseline;
	margin: 0 .14285714em;
	background-color: #E8E8E8;
	background-image: none;
	padding: .5833em .833em;
	color: rgba(0,0,0,.6);
	text-transform: none;
	font-weight: 700;
	border: 0 solid transparent;
	border-radius: .28571429rem;
	-webkit-transition: background .1s ease;
	transition: background .1s ease;
	font-size: 12px ;
}