@import url(https://fonts.googleapis.com/css?family=Kanit:400,400i&display=swap&subset=latin-ext,thai);
.content {
    background: #ffffff;
    padding: 24px;
    margin: 0px;
    min-height: 280px;
}

.nav-hamburger {
    display: none;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 0px;
    font-size: 35px;
}

.btn {
    margin-right: 10px;
    margin-right: 11px;
}
.ant-btn.ant-btn-block {
  border-radius: 8px;
}
.ant-btn {
  border-radius: 8px;
}

.ant-form-item {
    margin-bottom: 7px;
}
.ant-btn.ant-btn-block {
  border-radius: 8px;
}
.ant-btn {
  border-radius: 8px;
}

.ant-form-item {
    margin-bottom: 7px;
}
.ant-input-group-addon, .ant-input-group-wrap, .ant-input-group > .ant-input {
  border-radius: 8px;
}
.ant-input {
  border-radius: 8px;
}
.ant-input-number-input {
  border-radius: 8px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 8px;
}
.ant-radio-button-wrapper > .ant-radio-button {
  border-radius: 8px;
}
.ant-input-group {
  border-radius: 8px;
}

@media screen and (max-width: 750px) {
    .form-content {
        padding: 24px !important;
        background-color: #ffffff !important;
    }

    .card-content {
        /* padding: 6px !important; */
        background-color: #f0f2f5 !important;
    }

    .content {
        padding: 6px;
        background-color: #ffffff;
    }
    
    .nav-hamburger {
        display: block;
    }

    .navbar {
        display: none;
    }

    .btn {
        width: 100%;
        margin: 0 !important;
    }

    .ant-form-item {
        margin-bottom: 14px;
    }
}
button.create,
button.add,
button.create:active,
button.add:active {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}
button.edit,
button.edit:active {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}
button.delete,
button.delete:active {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}
button.back,
button.back:active {
  color: #fff;
  background-color: #616161;
  border-color: #616161;
}
button.action,
button.action:active {
  margin-bottom: 5px;
  display: block;
}

div.borderBottomItem {
  border-bottom: 1px solid #e8e8e8;
}
div.ant-steps-item.ant-steps-item-finish.ant-steps-item-custom div.stepTitle {
  color: #222;
}

div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-content {
  display: initial;
}
div.ant-steps-horizontal.ant-steps-label-horizontal {
  display: inline-block;
}
div.ant-steps.ant-steps-horizontal.ant-steps-label-horizontal {
  padding: 16px !important;
  display: flex;
}
div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
  display: inline-block;
}
div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
  display: inline-block;
  margin: 0;
}
div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
  margin: auto;
}
div.stepTitle {
  font-size: 15px;
  line-height: 21px;
  white-space: pre-line;
}
div.eventSteps.ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-tail {
  position: absolute;
  left: 16px;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 21px 0 6px;
}
div.eventSteps.ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-tail:after {
  height: 1px;
  width: 100%;
}
div.eventSteps div.ant-steps-item-custom .ant-steps-item-icon {
  width: 32px;
}
div.eventSteps div.ant-steps-item:last-child .ant-steps-item-icon.ant-steps-item-icon {
  width: auto;
  text-align: left;
}
div.eventSteps div.ant-steps-item:last-child .ant-steps-item-tail.ant-steps-item-tail {
  display: none;
}
div.eventSteps.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
  display: inline;
}
div.ant-steps-item-title {
  display: none;
}
@media only screen and (max-width: 769px) {
  div.stepTitle {
    font-size: 13px;
    line-height: 19px;
  }
  div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
    /* width: 52px; */
  }
}
@media only screen and (max-width: 480px) {
  div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-content {
    display: initial;
  }
  div.ant-steps-horizontal.ant-steps-label-horizontal {
    display: inline-block;
  }
  div.ant-steps.ant-steps-horizontal.ant-steps-label-horizontal {
    padding: 8px !important;
    text-align: center;
  }
  div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
    display: inline-block;
  }
  div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
    display: inline-block;
    margin: 0;
  }
  div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
    width: 46px;
  }
  div.stepTitle {
    font-size: 10px;
    line-height: 15px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-tail {
    position: absolute;
    left: 16px;
    top: 0;
    height: 100%;
    width: 100%;
    padding: 21px 0 6px;
  }
  div.ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-tail:after {
    height: 1px;
    width: 100%;
  }
}
div.ant-steps-item-custom.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon button,
div.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon button {
  color: #1890ff;
  border-color: #1890ff;
}
div.eventItem {
  padding: 20px;
}
form.eventForm {
  padding: 20px;
}
div.fixBottomPanel {
  position: fixed;
  bottom: 0;
  width: 100%;
  margin: auto;
  z-index: 5;
  background: white;
  border: 1px solid #dad4d4;
  padding: 3px;
  border-radius: 5px;
  left: 0;
  text-align: center;
}

.displayNone {
  display: none;
}

.metaPaymentStatistics thead.ant-table-thead {
  display: none;
}

.metaPaymentSummary thead.ant-table-thead {
  display: none;
}

div.sortableItems .item {
  border: 1px solid #bfbfbf;
  padding: 8px;
  cursor: move;
  max-width: 600px;
  border-radius: 5px;
  background: #f5f5f5;
  margin: 1px;
}
div.sortableItems {
  padding: 8px 0;
}

@media only screen and (max-width: 1001px) {
  div.paymentStatTableWrapper {
    overflow-x: scroll;
  }
  div.paymentStatTableWrapperInner {
    width: 1000px;
  }
}

button.inline {
  display: inline !important;
}
.ant-select-selection.ant-select-selection--single {
  min-width: 230px;
}

div.payments-mobile .ant-form-item-label {
  line-height: 20px !important;
}
div.payments-mobile .ant-form-item-control {
  line-height: 20px !important;
}
div.payments-mobile .ant-form-item {
  margin: 0 !important;
}

div.paymentSummary div.title {
  font-size: 20px;
  padding: 5px;
  margin-bottom: 16px;
}
div.paymentSummary .topPaymentDetail{
  margin-left: 16px;
}
div.paymentSummary td {
  word-break: break-word;
  border: 1px solid #e8e8e8;
}
div.paymentSummary .sum td {
  border-top: 2px solid black;
  border-bottom: 3px solid black;
}
div.paymentSummary .sumTop td {
  border-top: 2px solid black;
}
div.paymentSummary .topPaymentDetail p {
  margin-bottom: 5px;
}
div.paymentSummary {
  max-width: 795px;
}

div.paymentSummary .ant-table{
  font-size: 13px;
  /* font-family: 'Kanit', sans-serif; */
}


div.paymentSummary .ant-table-thead > tr > th,
div.paymentSummary .ant-table-tbody > tr > td {
  padding: 3px 8px;
}

div.paymentSummary div.marginTop {
  margin-top: 30px;
}

div.paymentSummary .ant-table-thead > tr > th {
  background: #e0dbdb;
}
div.paymentSummary .onlyHeader tbody {
  display: none;
}

@media print {
  .no-print {
    display: none !important;
  }
  div.paymentSummary .ant-table {
    font-size: 10px;
  }
  aside.ant-layout-sider.ant-layout-sider-dark {
    display: none;
  }
  header.header.ant-layout-header {
    display: none;
  }

  .topContent,
  .pro-sidebar,
  .ant-layout-sider-children,
  .printPdfBtn {
    display: none;
  }
  .desktop, 
  .topContent {
    border: unset !important;
  }
  .maincontent,
  .middleContent
   {
    padding: 0 !important;
    margin: 0 !important;
  }
  body {
    -webkit-print-color-adjust: exact !important;
  }
  thead.ant-table-thead {
    display: table-row-group;
  }
  .ant-layout-content {
    padding-top: 0 !important;
  }
  .filterTools {
    display: none;
  }

}

.disabled {
  pointer-events: none;
  color:#AAA;
  background:#F5F5F5;
}

.ticketGroupWrapper label.bigTitle span {
  font-size: 21px;
  padding: 0 3px;
}
div.ticketGroupWrapper {
  border-radius: 5px;
  padding: 0 2px;
  margin: 0;
  margin-bottom: 3px;
  max-width: 500px;
}
.ticketGroupWrapper .afterTicketGroup {
  padding: 0 30px
}
.ticketGroupWrapper .afterTicketGroup .ant-row.ant-form-item {
  margin: 0;
  margin-bottom: 15px;
}
div.ticketGroupPreviewWrapper {
  border-radius: 5px;
  padding: 0 2px;
  margin: 0;
  margin-bottom: 3px;
}
main.participantForm input,
main.participantForm textarea{
  max-width: 500px;
}
div.ebibPreviewLineHeight {
  line-height: 1.5 !important;
}
.colorGreen {
  color: green;
}
.colorOrange {
  color: orange;
}
.ant-upload.ant-upload-drag .ant-upload-drag-container {
  display: flex ;
  text-align: center;
  justify-content: center;
  max-width: auto;
  max-height: auto
}
.big-blue {
  font-size: 25px;
  color: #269afb;
}

.big-orange {
  font-size: 25px;
  color: #FB8226;
}

.data-div > p {
  margin: 0;
}

.sub-data {
  color: #cfcfcf;
}
.photos {
  text-align: center;
  padding: 1rem 2rem 80px;
  line-height: 0;
}
.photos .photo {
  display: inline-block;
  width: 200px;
  height: 200px;
  overflow: hidden;
  text-align: center;
  position: relative;
  cursor: pointer;
  background: #dcdcdc;
  cursor: pointer;
  margin: 2px;
}
.photos .photo:hover {
  /* outline: 5px solid #85b7d9; */
  /* overflow: initial !important; */
/*box-shadow: 0 0 2px 2px grey;*/
}
.photos .photo .image {
  position: relative;
  position: static;
}
/* .photos .photo .image :hover {
  z-index: 1000;
  outline: 4px solid white;
} */
.photos .photo .image img {
  position: absolute;
  margin: auto;
  left: -300px;
  right: -300px;
}
.photo-viewer {
    /* display: block; */
    text-align: center;
}

/* .tag-tools{
    position: absolute;
    flo
    margin-top: 10px;
} */

.tag-tools{
    width: 150px;
}

.tag-description{
    /* position: absolute; */
    position: relative;
    margin: 0 auto;
    text-align: center;
    /* right: 0px;
    top: 0px; */
    background-color: #fff;
    /* box-shadow: 0 1px 2px 0 rgba(34,36,38,.15); */
    /* margin: 1rem 0; */
    padding: 1em;
    /* border-radius: .28571429rem; */
    /* border: 1px solid rgba(34,36,38,.15); */
    width: 500px
}

.tag-name {
    display: inline-block;
    white-space: nowrap;
    word-wrap: break-word;
    line-height: 1;
    vertical-align: baseline;
    margin: 0 .14285714em;
    background-color: #e8e8e8;
    border-color: #e8e8e8;
    background-image: none;
    padding: .5833em .833em;
    color: rgba(0,0,0,.6);
    text-transform: none;
    font-weight: 700;
    border-radius: .28571429rem;
    transition: background .1s ease;
}

.tag-description.tag-name{
    float: right;
    clear: both;
    margin: .25rem 0;
}

.menu.toolbar {
    position: fixed;
    right: 1rem;
    z-index: 1;
}

.menu.toolbar .item>.ui.label {
    margin: 0;
    float: none;
}

.ui.segment.tags {
    position: fixed;
    text-align: right;
    right: 1rem;
    top: 300px
}

.ui.segment.tags .ui.label {
    float: right;
    clear: both;
    margin: .25rem 0;
}

.photo-edit {
    text-align: center;
    padding-top: 5em;
}

#canvas {
    /*width:2000px;*/
    /*height:2000px;*/
    position: relative;
    outline: 1px solid gainsboro;
    /*border: 10px solid transparent;*/
    cursor: default;
    display: inline-block;
}

#canvas img {
    min-width: 400px;
    min-height: 400px;
    max-width: 800px;
    max-height: 800px;
}

#canvas.drawing {
    cursor: crosshair;
}

.rectangle {
    display: block;
    border: 1px solid white;
    box-shadow: 0 0 2px 2px gray;
    position: absolute;
    pointer-events: auto;
}

.rectangle.idle {
    display: none;
}

.rectangle.drawing {
    pointer-events: none;
}

.rectangle:hover {
    box-shadow: 0 0 2px 2px gainsboro;
}

.rectangle[data-tag]:hover::before {
    content: attr(data-tag);
    top: 5px;
    left: 5px;
    position: absolute;
    display: inline-block;
    line-height: 1;
    vertical-align: baseline;
    margin: 0 .14285714em;
    background-color: #E8E8E8;
    background-image: none;
    padding: .5833em .833em;
    color: rgba(0, 0, 0, .6);
    text-transform: none;
    font-weight: 700;
    border: 0 solid transparent;
    border-radius: .28571429rem;
    transition: background .1s ease;
    font-size: 12px;
}

.boundary {
    overflow: hidden;
}

.boundary img {}

#tagger {
    display: none;
    position: absolute;
    width: 200px;
    text-align: center;
    z-index: 1;
}

#tagger.tagging {
    display: block;
}

.ui.save.button:not(.saved) .icon {
    display: none;
}

.message {
    text-align: center;
}
.rectangle {
	display: block;
	border: 1px solid white;
	box-shadow: 0 0 2px 2px gray;
	position: absolute;
	pointer-events: auto;
}
.rectangle.idle{
	display: none;
}
.rectangle.drawing {
	pointer-events: none;
}
.rectangle:hover {
	box-shadow: 0 0 2px 2px gainsboro;
}

.rectangle[data-tag]:hover::before{
	content: attr(data-tag);
	top: 5px;
	left: 5px;
	position: absolute;

	display: inline-block;
	line-height: 1;
	vertical-align: baseline;
	margin: 0 .14285714em;
	background-color: #E8E8E8;
	background-image: none;
	padding: .5833em .833em;
	color: rgba(0,0,0,.6);
	text-transform: none;
	font-weight: 700;
	border: 0 solid transparent;
	border-radius: .28571429rem;
	transition: background .1s ease;
	font-size: 12px ;
}
.react-fine-uploader-gallery-file-input-container {
  width: initial;
}

.react-fine-uploader-file-input-container {
  display: block !important;
}
.p {
    margin-bottom: '1em' !important;
    font-size: '14px';
    background-color: blueviolet;
}

.ant-avatar-lg_big{
    width: 200px !important;
    height: 200px !important;
    line-height: 200px !important;
    border-radius: 100px !important;
    font-size: 100px !important;
}


/* @import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@400;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import '~react-photoswipe/dist/photoswipe.css';
@font-face {
  font-family: 'DBHeavent';
  src: url('./assets/fonts/DB\ Heavent\ v3.2.1.ttf') format('truetype');
}
@font-face {
  font-family: 'DBHeavent-Light';
  src: url('./assets/fonts/DB\ Heavent\ Li\ v3.2.1.ttf') format('truetype');
} */


.print {
  margin: 0 2em;
  padding-top: 2em;
  font-family: 'Sarabun', sans-serif;
  font-size: 16px;
}
/* 
@media print {
  .nonPrintDiv {
    display: none;
  }
  .printDiv {
    display: block;
  }
} */

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

.rounded-container {
  max-width: 600px;
  margin: 12px auto;
  border: 1px solid gainsboro;
  padding: 12px;
  border-radius: 6px;
}
.ant-tooltip-inner {
  /* width: 350px; */
  white-space: pre-line;
}

/* #buy-credits {
  max-width: 600px;
  margin: 12px auto;
  border: 1px solid gainsboro;
  padding: 12px;
  border-radius: 6px;
} */

#buy-credits .ant-list-split .ant-list-item:hover {
  background: #F8F8F8;
  cursor: pointer;
  /* border-bottom: 1px solid #e8e8e8; */
  margin: auto -12px;
  padding-left: 12px;
  padding-right: 12px;
}

.campaign.markdown img {
    width: 100%;
}

#profile-popover .ant-popover-inner-content {
  padding: 0;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border: none;
}

/* @media(max-width: 480px) { 
  .ant-picker-panels { 
    flex-direction: column;
  } 
} */

@media (max-width: 480px) {
  .ant-calendar-range {
      width: 320px;
  }
  .ant-calendar-range-part {
      width: 100%;
  }
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

