.big-blue {
  font-size: 25px;
  color: #269afb;
}

.big-orange {
  font-size: 25px;
  color: #FB8226;
}

.data-div > p {
  margin: 0;
}

.sub-data {
  color: #cfcfcf;
}