.p {
    margin-bottom: '1em' !important;
    font-size: '14px';
    background-color: blueviolet;
}

.ant-avatar-lg_big{
    width: 200px !important;
    height: 200px !important;
    line-height: 200px !important;
    border-radius: 100px !important;
    font-size: 100px !important;
}
