
/* @import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@400;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import '~react-photoswipe/dist/photoswipe.css';
@font-face {
  font-family: 'DBHeavent';
  src: url('./assets/fonts/DB\ Heavent\ v3.2.1.ttf') format('truetype');
}
@font-face {
  font-family: 'DBHeavent-Light';
  src: url('./assets/fonts/DB\ Heavent\ Li\ v3.2.1.ttf') format('truetype');
} */


.print {
  margin: 0 2em;
  padding-top: 2em;
  font-family: 'Sarabun', sans-serif;
  font-size: 16px;
}
/* 
@media print {
  .nonPrintDiv {
    display: none;
  }
  .printDiv {
    display: block;
  }
} */

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

.rounded-container {
  max-width: 600px;
  margin: 12px auto;
  border: 1px solid gainsboro;
  padding: 12px;
  border-radius: 6px;
}
.ant-tooltip-inner {
  /* width: 350px; */
  white-space: pre-line;
}

/* #buy-credits {
  max-width: 600px;
  margin: 12px auto;
  border: 1px solid gainsboro;
  padding: 12px;
  border-radius: 6px;
} */

#buy-credits .ant-list-split .ant-list-item:hover {
  background: #F8F8F8;
  cursor: pointer;
  /* border-bottom: 1px solid #e8e8e8; */
  margin: auto -12px;
  padding-left: 12px;
  padding-right: 12px;
}

.campaign.markdown img {
    width: 100%;
}

#profile-popover .ant-popover-inner-content {
  padding: 0;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border: none;
}

/* @media(max-width: 480px) { 
  .ant-picker-panels { 
    flex-direction: column;
  } 
} */

@media (max-width: 480px) {
  .ant-calendar-range {
      width: 320px;
  }
  .ant-calendar-range-part {
      width: 100%;
  }
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
